.home-container {
  height: 100vh; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(135deg, #264653, #2a9d8f);  */
  background: linear-gradient(135deg, #9330d1, #4acd62); 
  color: #e9c46a; 
  text-align: center;
  padding: 20px;
}

.home-title {
  font-size: 3rem;
  margin-bottom: 16px;
  color: #e9c46a; 
  animation: fadeInDown 1s ease-in-out;
}

.home-typeable-text {
  font-size: 1.8rem;
  color: #f4a261; 
  margin-bottom: 24px;
  animation: fadeIn 1.5s ease-in-out;
}

.home-text {
  font-size: 1.5rem;
  margin-bottom: 24px;
  color: #f4a261; 
  max-width: 600px;
  animation: fadeInUp 1s ease-in-out;
}

.home-button {
  background-color: #2a9d8f; 
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1.2rem;
  border-radius: 5px;
  animation: fadeInUp 1.5s ease-in-out;
}

.home-button a{
  color: white;
}

.home-button a {
  text-decoration: none;
  color: #264653;
}

.home-button:hover {
  background-color: #e76f51; 
}

/* Responsive Design */
@media (max-width: 768px) {
  .home-title {
    font-size: 2.5rem;
  }

  .home-text {
    font-size: 1.2rem;
  }

  .home-button {
    font-size: 1rem;
    padding: 10px 20px;
  }
}

/* Animations */
@keyframes fadeInDown {
  from { opacity: 0; transform: translateY(-50px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(50px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
