.projects-container {
  padding: 40px 20px;
  background-color: #f4f4f4; 
  text-align: center;
  background: linear-gradient(135deg, #264653, #2a9d8f); 
  color: #ffffff; 
}

.projects-title {
  font-size: 2.5rem;
  color: #e9c46a; 
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
  animation: fadeIn 1s ease-in-out;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-radius: 8px;
  background: rgb(131,58,180);
  background: linear-gradient(313deg, rgba(131,58,180,1) 0%, rgba(253,29,29,0.557387920988708) 50%, rgba(252,176,69,1) 100%);
  color: #ffffff; 
  animation: fadeInUp 1.5s ease-in-out;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.card-title {
  color: #264653; 
  font-size: 1.5rem;
  margin-bottom: 15px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.card-text {
  font-size: 1rem;
  color: #ffffff; 
  margin-bottom: 20px;
  max-height: 4.5em; /* Limit to 3 lines */
  overflow: auto;
}

.read-more-btn {
  display: inline-block;
  margin-top: 10px;
  color: #264653;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: underline;
}

.read-more-btn:hover {
  color: #e76f51;
}


.view-project {
  background-color: #e76f51; 
  color: #ffffff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.view-project:hover {
  background-color: #f4a261;
}



@media (max-width: 768px) {
  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
