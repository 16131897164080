.about-container {
  background-color: #80b918;
  background-image: linear-gradient(to top, #d9afd9 0%, #97d9e1 100%);
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.about-text {
  margin-bottom: 16px;
  line-height: 1.6;
  flex: 1 1 50%;
  animation: slideInLeft 2s ease-in-out forwards;
  opacity: 0; /* Initially hidden for animation */
  padding: 20px;
}

.about-text p {
  color: rgb(12, 158, 194);
  max-width: 700px;
  margin: 0 auto;
  font-size: 1.8rem;
}

.about-image {
  width: 100%;
  max-width: 450px;
  height: auto;
  margin-bottom: 24px;
  flex: 1 1 50%;
  animation: slideInRight 2s ease-in-out forwards;
  opacity: 0; /* Initially hidden for animation */
}

/* Responsive Design */
@media (max-width: 768px) {
  .about {
    flex-direction: column;
  }

  .about-text {
    order: 2; /* Text will appear below the image on smaller screens */
    flex: 1 1 100%;
    text-align: center;
  }

  .about-text p {
    width: 100%;
  }

  .about-image {
    order: 1; /* Image will appear above the text on smaller screens */
    flex: 1 1 100%;
    max-width: 300px;
    margin-bottom: 16px;
  }
}

/* Animation for text coming from the left */
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Animation for image coming from the right */
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
