/* src/assets/styles.css */

body {
    font-family: 'Roboto', sans-serif;
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: #00d768;
  }
  
  p {
    color: #ffffff;
  }
  