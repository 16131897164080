
/* WhatsappDialog */

/* .whatsapp-container {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 1000;
  }
  

  .whatsapp-icon {
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .whatsapp-icon img {
    width: 100%;
    height: 100%;
  }
  
  .whatsapp-icon.active {
    transform: rotate(360deg);
  }
  
  
  .whatsapp-dialog {
    position: absolute;
    bottom: 60px;
    right: 0;
    width: 300px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 2px;
    box-shadow: 0 4px 8px rgba(230, 225, 225, 0.814);
    padding: 1rem;
    font-family: Arial, sans-serif;
    z-index: 1001;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  
  .whatsapp-header {
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.5rem;
  }
  
  .whatsapp-header h2 {
    margin: 0;
    font-size: 1.25rem;
    color: #00796b;
  }
  
  
  .whatsapp-body {
    margin-top: 0.5rem;
  }
  
  .whatsapp-body p {
    margin: 0 0 1rem;
    color: #333;
  }
  
  .whatsapp-body button {
    background-color: #25d366;
    color: white;
    margin-left: 18%;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 1px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .whatsapp-body button:hover {
    background-color: #168c12;
  }
  @media only screen and (max-width: 600px) {
    .whatsapp-dialog {
      position: absolute;
      bottom: 60px;
      right: 10px;
      width: 300px;
      background-color: #ffffff;
      border: 1px solid #ddd;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      padding: 1rem;
      font-family: Arial, sans-serif;
      z-index: 1001;
      transition: opacity 0.3s ease, transform 0.3s ease;
    }
  }   */








  .whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: white;
    font-size: 28px;
    padding: 15px;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 60px;
    height: 60px;
    animation: float 1s infinite ease-in-out;
  }
  
  .whatsapp-button:hover {
    background-color: #128c7e;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  }
  
  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  