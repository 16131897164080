@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

.header {
  background: linear-gradient(135deg, #a8f6ed, #1a323b);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.header-logo {
  margin-top: 5px;
  animation: fadeInLeft 1s ease-in-out;
}

.header-logo:hover {
  transform: scale(1.1);
}

figcaption{
  color: rgb(153, 0, 255);
  /* color: rgb(255, 255, 255); */
  animation: fadeInLeft 1s ease-in-out;
  font-family: "Lobster", serif;
  font-size: 15px;
}

.header-nav {
  display: flex;
  gap: 16px;
  animation: fadeInRight 1s ease-in-out;
}

button .nav-link:hover{
  color: rgb(0, 255, 242);
  border-bottom: 2px solid greenyellow;
}

/* Hide the menu icon on larger screens */
.menu-icon {
  display: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .header-nav {
    display: none; /* Hide the nav initially */
    position: absolute;
    top: 64px;
    right: 0;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(26, 50, 59, 0.9);
    gap: 24px;
    transition: transform 0.5s ease-in-out;
    z-index: 10;
  }

  /* Show the nav when 'open' class is added */
  .header-nav.open {
    display: flex;
    transform: translateX(0);
  }

  button .nav-link:hover{
    color: rgb(0, 255, 242);
  }

  .menu-icon {
    display: block;
    background-color: transparent;
    border: none;
    padding: 8px;
    cursor: pointer;
    animation: fadeInRight 1s ease-in-out;
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 2px;
  }

  .navbar-toggler-icon:before, 
  .navbar-toggler-icon:after, 
  .navbar-toggler-icon span {
    display: block;
    content: '';
    background-color: #a8f6ed;
    height: 3px;
    margin: 5px 0;
    border-radius: 2px;
  }
}

@keyframes fadeInLeft {
  from { opacity: 0; transform: translateX(-50px); }
  to { opacity: 1; transform: translateX(0); }
}

@keyframes fadeInRight {
  from { opacity: 0; transform: translateX(50px); }
  to { opacity: 1; transform: translateX(0); }
}
