.footer {
  background-color: #264653;
  color: #ffffff;
  padding: 20px 0;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-title {
  font-size: 1.25rem;
  margin-bottom: 20px;
  color: #e9c46a;
}

.footer p {
  font-size: 1rem;
  line-height: 1.6;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #e76f51;
}

.social-links a {
  display: inline-block;
  color: #ffffff;
  font-size: 1.5rem;
  transition: transform 0.3s ease, color 0.3s ease;
  margin-right: 15px;
}

.social-links a:hover {
  transform: scale(1.1);
  color: #2a9d8f;
}




.footer-bottom {
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 30px;
}

.footer-bottom p {
  margin: 0;
  font-size: 0.9rem;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-section {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
  }

  .footer-section.follow-us-quick-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
  }

  .social-links {
    display: flex;
    flex-direction: column;
  }

  .social, .quick-links {
    width: 48%;
  }
}

/* Larger devices (e.g., tablets and desktops) */
@media (min-width: 769px) {
  .footer-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
  }

  .footer-section {
    flex: 1;
    max-width: none;
    margin-bottom: 0;
  }

  .footer-links{
    text-align: center;
  }

  .footer-section.follow-us-quick-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .social-links {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .social, .quick-links {
    width: auto;
  }

  .social-links a {
    margin-right: 15px;
  }
}
