.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100vh;
  background: linear-gradient(135deg, rgba(38, 70, 83, 0.8), rgba(244, 162, 97, 0.8)), url('../../../public/contact-bg.jpg') no-repeat center center fixed;
  background-size: cover;
}

.glass-effect {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 40px;
  max-width: 500px;
  width: 100%;
  animation: fadeInUp 1s ease-out;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .glass-effect {
    padding: 20px;
    max-width: 100%;
  }

  .contact-form h2 {
    font-size: 1.5rem;
  }
}

.contact-form h2 {
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 2rem;
  text-align: center;
  animation: slideInFromLeft 1.2s ease-in-out;
}

.form-group {
  margin-bottom: 20px;
  animation: fadeIn 1.2s ease-in-out;
}

.form-control {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.form-control:focus {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.15);
}

.submit-btn {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background-color: #e76f51;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-btn:hover {
  background-color: #f4a261;
  transform: translateY(-3px);
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
