
.portfolio-container {
  padding: 40px;
  background: linear-gradient(135deg, #9330d1, #4acd62);
  text-align: center;
}

.portfolio-title {
  font-size: 2.5rem;
  color: #ffffff; 
  margin-bottom: 40px;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.portfolio-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.portfolio-card:hover {
  transform: translateY(-10px);
}

.portfolio-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.portfolio-content {
  padding: 20px;
}

.portfolio-content h3 {
  font-size: 1.5rem;
  color: #2a9d8f;
  margin-bottom: 10px;
}

.portfolio-content p {
  font-size: 1rem;
  color: #555555; 
  margin-bottom: 20px;
  max-height: 4.5em; /* Limits to 3 lines */
  overflow: auto;
}

.read-more-btn {
  display: inline-block;
  margin-right: 10px;
  color: #f7f8f8;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
  background-color: #e76f51;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.read-more-btn:hover {
  background-color: #f4a261;
}

.portfolio-button {
  background-color: #e76f51; 
  color: #ffffff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.portfolio-button:hover {
  background-color: #f4a261; 
}
