.services-container {
  padding: 48px;
  /* background-color: #264653; */
  background: rgb(251,199,199);
  background: linear-gradient(84deg, rgba(251,199,199,1) 0%, rgba(160,141,233,1) 77%, rgba(90,95,225,1) 100%);
  color: #fefefd; /* Yellow */
  text-align: center;
}

.services-title {
  font-size: 2.5rem;
  margin-bottom: 32px;
  color: #4605f9; /* Orange */
}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
}

.service-card {
  background-color: #2a9d8f; /* Teal */
  padding: 24px;
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
}

.service-icon {
  font-size: 3rem;
  margin-bottom: 16px;
  color: #a6cb3d; /* Red-Orange */
}

.service-title {
  font-size: 1.5rem;
  margin-bottom: 16px;
  color: #f4a261; /* Orange */
}

.service-description {
  font-size: 1rem;
  color: #ffffff; /* Yellow */
  line-height: 1.6;
}
